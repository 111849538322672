import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "gatsby";
import React, {useEffect, useState} from "react";
import { BASE_URL } from "../../assets/js/_variables";

// Componentes
import Layout from "../../components/Layout";
import Slider from "../../components/Slider";

const cooking_classes = () => {
  const [lang, setLang] = useState()
  
  const txt = {
    en: {
      pageTitle: 'COOKING CLASSES | HB Privileged',
      title: 'COOKING CLASSES',
      subtitle: 'TOUR OVERVIEW',
      text: "Although we have several companies with whom we offer different culinary exoeriences, it is our pleasure to recommend you the fantastic Chocomuseum. Located in the heart of Puerto Vallarta, just a few blocks from the Buenaventura Grand hotel, the chefs of this establishment will make you feel right at home. Wheather you want to learn how to cook delicious chocolate truffles, or a traditional mole poblano, the staff will guide you step by step so you can become an expert in gastronomy. It is fun and learning for the whole family, given that they have mini chef programs as well (children under 12 years of age). It ́s necessary to make a reservation because the challenge is to create delicious dishes from scratch, so the will even take you on a grocery shopping tour to the Municipal Market to gert the ingredients for your dishes. While you cook, learn the history of Mexico and the mexican culture that bring to life the world renowned Mexican Gastronomy. The will wait for you with open arms from Monday thru Sunday.",
      frequency: 'Frequency:',
      hours: 'Hours:',
      duration: 'Duration:',
      btn: 'Book now',
    },
    es: {
      pageTitle: 'CLASES DE COCINA | HB Privileged',
      title: 'CLASES DE COCINA',
      subtitle: 'RESUMEN DEL TOUR',
      text: 'Aunque tenemos varias compañías con las que ofrecemos diferentes experiencias culinarias, es un placer recomendarle el fantástico Chocomuseo. Localizado en el corazón de Puerto Vallarta, a sólo unas cuadras del hotel Buenaventura Grand, los chefs de deste establecimientos lo harán sentir como en casa. Ya sea que desée aprender a cocinar unas deliciosas trufas de chocolate, o un mole poblano, el personal lo guiará paso a paso para que se convierta en un experto de la gastronomía. Es diversión y aprendizaje para toda la familia, ya que también tienen programas para mini chefs (niños menores de 12 años). Es necesario hacer reservación pues el reto es crear deliciosos platillos desde el principio, es decir que incluso pueden acompañarte al Mercado Municipal para hacer las compras para tus platillos. También mientras cocinas, aprende la historia y la cultura mexicana que dan vida a la mundialmente famosa Gastronomía Mexicana. Los esperan de lunes a domingo con los brazos abiertos.',
      frequency: 'Frecuencia:',
      hours: 'Horas:',
      duration: 'Duración:',
      btn: 'Reserva este tour',
    }
  }

  const images = [
    `${BASE_URL}/tours/cooking-classes1.jpg`,
    `${BASE_URL}/tours/cooking-classes2.jpg`,
    `${BASE_URL}/tours/cooking-classes3.jpg`,
  ]

  const getLang = () => {
    if (typeof window !== 'undefined') {
      return localStorage.getItem('lang')
    }
  }

  useEffect(() => {
    if(getLang() === 'es') {
      setLang('es')
    } else {
      setLang('en')
    }
  }, [])

  return (
    <Layout pageTitle={ lang === 'es' ? txt.es.pageTitle : txt.en.pageTitle }>
      <div>
        {/*</div><div className="h-80 overflow-hidden">*/}
        <div className="h-70 overflow-hidden">
          <img src={`${BASE_URL}/banners/cooking-classes.jpg`} className="w-full object-cover" alt="" />
        </div>
        <div className="section">
          <h2 className="text-2xl md:text-4xl font-bold mb-2">
            { lang === 'es' ? txt.es.title : txt.en.title }
          </h2>
          <div className="mb-4">
            <FontAwesomeIcon className="text-amber-400" icon={faStar} />
            <FontAwesomeIcon className="text-amber-400" icon={faStar} />
            <FontAwesomeIcon className="text-amber-400" icon={faStar} />
            <FontAwesomeIcon className="text-amber-400" icon={faStar} />
            <FontAwesomeIcon className="text-amber-400" icon={faStar} />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <h3 className="text-xl md:text-2xl font-semibold mb-4">
                { lang === 'es' ? txt.es.subtitle : txt.en.subtitle }
              </h3>
              <p className="normal-text text-justify mb-4">
                { lang === 'es' ? txt.es.text : txt.en.text }
              </p>
              <p className="normal-text">
                { lang === 'es' ? txt.es.frequency : txt.en.frequency }
              </p>
              <p className="normal-text">
                { lang === 'es' ? txt.es.hours : txt.en.hours }
              </p>
              <p className="normal-text">
                { lang === 'es' ? txt.es.duration : txt.en.duration }
              </p>
            </div>

            <div className="">
              <div className="rounded overflow-hidden">
                <Slider images={images} />
              </div>
              
              <div className="text-center py-8">
                <Link
                  className="text-center w-1/2 mt-16 text-xl font-bold bg-sky-800 text-neutral-50 py-2 px-4 rounded-full" 
                  to={`${BASE_URL}/contacto`}
                >
                  { lang === 'es' ? txt.es.btn : txt.en.btn }
                </Link>
              </div>
            </div>
          </div>
        </div>

      </div>
    </Layout>
  );
}
 
export default cooking_classes;